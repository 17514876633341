legend {
    background-color: transparent !important;
    color: #a1a1a1 !important;
    padding: 0 !important;
    border-radius: 8px;
    display: block !important;
    font-weight: 500;
    width: auto;
}

fieldset {
    border-radius: 5px;
}