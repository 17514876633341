legend {
  background-color: #0c2d6a;
  color: white;
  padding: 15px;
  border-radius: 8px;
  width: 98%;
  font-weight: 500;
}

fieldset {
  border: 0.5px solid rgb(201, 201, 201);
}

.filter-input {
  width: 100%;
}

.filter-button {
  margin-top: 10px !important;
}

.root-title {
  flex-grow: 1;
  margin-top: 15px;
  margin-bottom: 10px;
}

.title-title {
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  color: #0c2d6a;
}

.title-grid-align {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#title-button {
  color: white !important;
  margin: 10px;
  float: right;
  margin-top: 25px;
}

#title-icon {
  height: 60px;
  width: 60px;
}

#title-icon-settings {
  float: right;
  margin: 10px;
  margin-top: 30px;
}

.title-title {
  margin-left: 15px;
}
.inline {
  display: inline;
}

.padding10 {
  padding: 10px;
}

.root-filter {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.btn-cancelar {
  margin-right: 10px !important;
}
