.MuiGrid-root::-webkit-scrollbar {
  width: 8px !important;
  height: 12px !important;
}

.MuiGrid-root::-webkit-scrollbar-track {
  background-color: #efefef !important;
}

.MuiGrid-root::-webkit-scrollbar-thumb {
  background-color: lightgray !important;
  border-radius: 20px !important;
}

.gridStyleItemFiles {
  padding: 5px;
  justify-content: space-between;
  height: 100px;
  max-height: 100px;
  margin-top: 5px;
  display: flex;
  border-radius: 4px;
  background-color: #f5f5f9;
  margin-right: 10px;
}
.gridStyleItemFiles:hover {
  border-radius: 5px;
  background-color: #d5d5db;
}

#publicInteraction_rte-edit-view::-webkit-scrollbar {
  width: 12px !important;
  height: 12px !important;
}

#publicInteraction_rte-edit-view::-webkit-scrollbar-track {
  background-color: #dddddd !important;
}

#publicInteraction_rte-edit-view::-webkit-scrollbar-thumb {
  background-color: #8995a6 !important;
  border-radius: 20px !important;
}

#privateInteraction_rte-edit-view::-webkit-scrollbar {
  width: 12px !important;
  height: 12px !important;
}

#privateInteraction_rte-edit-view::-webkit-scrollbar-track {
  background-color: #dddddd !important;
}

#privateInteraction_rte-edit-view::-webkit-scrollbar-thumb {
  background-color: #8995a6 !important;
  border-radius: 20px !important;
}
