.actionCard {
  width: 150px;
  height: 50px;
  border-radius: 5px;
  background-color: #f2f2f2;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.actionCard-selected {
  width: 150px;
  height: 50px;
  border-radius: 5px;
  background-color: rgb(21, 101, 192);
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.contextContainer {
  background-color: #f2f2f2;
  padding: 16px;
  border-radius: 10px;
  text-align: left;
  position: relative;
}

.carousel .slide iframe {
  width: 100% !important;
  margin: 0px !important;
}

.mainContainer {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 85%;
}

.carousel.carousel-slider .control-arrow {
  visibility: hidden;
}

