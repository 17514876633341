@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto";
}

#EMAIL_EDITOR_WRAPPER iframe {
  min-width: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.datagrid-incomplete-address-row {
  background-color: #f2d8b0 !important;
}

.BrainhubCarousel__dots {
  align-items: center;
}

.BrainhubCarousel__dot::before {
  background: #0c2d6a !important;
  width: 8px !important;
  height: 8px !important;
}

.BrainhubCarousel__dot {
  padding: 2px !important;
  opacity: 0.2 !important;
  height: 15px !important;
}

body::-webkit-scrollbar {
  width: 12px !important;
  height: 12px !important;
}

body::-webkit-scrollbar-track {
  background-color: #dddddd;
}

body::-webkit-scrollbar-thumb {
  background-color: #8995a6;
  border-radius: 20px;
}

.MuiList-root::-webkit-scrollbar {
  width: 12px !important;
  height: 12px !important;
}

.MuiList-root::-webkit-scrollbar-track {
  background-color: #0a66c2;
}

.MuiList-root::-webkit-scrollbar-thumb {
  background-color: #f0f0f0c5;
  border-radius: 20px;
}

.MuiDialogContent-root::-webkit-scrollbar {
  width: 12px !important;
  height: 12px !important;
}

.MuiDialogContent-root::-webkit-scrollbar-track {
  background-color: #dddddd;
}

.MuiDialogContent-root::-webkit-scrollbar-thumb {
  background-color: #8995a6;
  border-radius: 20px;
}

.MuiDataGrid-window::-webkit-scrollbar {
  width: 12px !important;
  height: 12px !important;
}

.MuiDataGrid-window::-webkit-scrollbar-track {
  background-color: #dddddd;
}

.MuiDataGrid-window::-webkit-scrollbar-thumb {
  background-color: #8995a6;
  border-radius: 20px;
}

.swal2-container {
  z-index: 1400;
}

.swal2-styled.swal2-confirm {
  background-color: #0a73db;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgb(21 5 185 / 50%);
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
.athContainer {
  background-color: #fff;
  box-shadow: rgb(82 62 62 / 15%) 0px 0px 50px 0px;
  border-radius: 10px;
  color: #000000;
  display: none;
  position: sticky;
  width: 100%;
  bottom: 0;
  z-index: 1000;
  padding: 2%;
  padding: 16px;
}

@media (max-width: 767px) {
  .athContainer {
    display: none;
  }
}

@media (min-width: 768px) {
  .athContainer {
    padding: 16px;
    max-width: 530px;
    margin: auto;
    width: 75%;
    position: absolute;
    height: 55px;
  }
}

@media (min-width: 1048px) {
  .athContainer {
    width: 100%;
    position: fixed;
    right: 0;
  }
}

.athBanner {
  display: flex;
  grid-template-areas: "logo content" "install install";
  position: relative;
  grid-template-columns: 1fr 3fr;
}

.athBanner .athCancelButton {
  text-decoration: none;
  border: none;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  height: 30px;
  padding: 0 20px;
  margin-right: 10px;
  outline: none;
  align-items: center;
  background: red !important;
  margin-top: 12px;
  color: #ffffff;
  border-radius: 10px;
  font-weight: bold;
}

.athContentCell {
  align-items: center;
}

.athLogoCell {
  text-align: center;
  grid-area: logo;
}

.athTitleCell {
  align-self: center;
  grid-area: content;
}

.athInstallButtonCell {
  text-align: right;
  grid-area: install;
}

.athInstallButtonCell .button {
  display: unset;
}

.athGuidance {
  display: none;
  grid-template-areas: "cancel" "image";
  position: relative;
}

.athGuidance .athCancelButtonCell {
  grid-area: cancel;
  text-align: right;
}
.athGuidanceImageCell {
  grid-area: image;
  text-align: center;
}

.athGuidanceCancelButton {
  width: 15px;
  height: 15px;
  cursor: pointer;
  border: 0;
}

.athLogo {
  border-radius: 10px;
  width: 50px;
  margin-right: 10px;
}

.athTitle {
  font-weight: bold;
  font-size: large;
  width: 90%;
}

.d-grid {
  display: grid !important;
}

.button {
  text-decoration: none;
  border: none;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  height: 30px;
  padding: 0 20px;
  outline: none;
  align-items: center;
  margin-top: 12px;
  background: rgb(10, 115, 219) !important;
  color: #ffffff;
  border-radius: 10px;
  font-weight: bold;
}

.botVideoAction {
  width: 100%;
  overflow: hidden;
}

.botMessageText > a {
  color: #82e9df !important;
}

.react-resizable {
  overflow-y: hidden !important;
}

#editor-1 iframe {
  min-width: 100% !important;
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: inherit !important;
  }
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 5px !important;
  right: 5px !important;
}

.carousel .control-dots .dot {
  background-color: #0a66c2 !important;
}

.bot-webapp {
  width: 100%;
  height: 100%;
  position: absolute !important;
  z-index: 1200;
}

.Toastify__toast-body {
  z-index: 999999999 !important;
}
