.scrolltextarea {
  overflow: auto;
  overflow-x: hidden;
}

.scrolltextarea::-webkit-scrollbar-track {
  background-color: #dddddd;
}

.scrolltextarea::-webkit-scrollbar {
  width: 12px !important;
  height: 12px !important;
}

.scrolltextarea::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #8995a6;
}
.scrolltextarea:focus {
  outline: 1px solid #0a73db;
}
.scrolltextarea {
  resize: none;
  border: 1px solid #d2d2d3;
  border-radius: 5px;
  background-color: #f5f5f9;
  padding: 1%;
  font-size: 16px;
  overflow-y: auto;
}
